
@import '../helpers/variables.scss';

/* Content QR
   ========================================================================== */

@media (orientation: landscape) {
	.content-qr-wrap-portrait {
		display: none;
	}

	.content-qr {
		text-align: right;

		img {
			max-height: 7em;
			opacity: 0.75;
		}

		canvas {
			height: 6em !important;
			width: 6em !important;
		}
	}
}

@media (orientation: portrait) {
	.content-qr-wrap-landscape {
		display: none;
	}

	.content-qr-wrap-portrait {
		flex: 1;
		padding: 0 3.5em;
	}

	.content-qr {
		span {
			display: block;
			margin-bottom: 0.75em;
		}

		img {
			max-height: 11em;
		}

		canvas {
			height: 10em !important;
			width: 10em !important;
		}
	}
}

.content-qr {
	font-size: 1.5rem;
	font-weight: 700;
}
