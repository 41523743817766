
@import '../helpers/variables.scss';

/* Content Tag
   ========================================================================== */

@media (orientation: landscape) {
	.content-tag-wrap-portrait {
		display: none;
	}

	.content-tag {
		position: absolute;
		top: -0.5em;
		right: -1em;
	}
}

@media (orientation: portrait) {
	.content-tag-wrap-landscape {
		display: none;
	}

	.content-tag {
		position: absolute;
		top: 1em;
		right: 1em;
	}
}

.content-tag {
	/* Color */
	background-color: $content_accent_bg_color;
	color: $content_accent_text_color;

	/* Position & Behaviour */
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	padding: 0.4em 0.8em;

	/* Typographie */
	font-size: 1.625rem;
	font-weight: 700;
}
