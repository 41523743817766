@import '../helpers/variables.scss';

/* ==========================================================================
   News Ticker
   ========================================================================== */

@media (orientation: portrait) {
	// no code needed
}

.news-ticker-wrap {
	/* Color */
	background-color: $newsticker_bg_color;
	color: $newsticker_text_color;

	/* Position & Behaviour */
	box-sizing: content-box;
	overflow: hidden;
	padding-left: 100%;
	width: 100%;

	.news-ticker {
		/* Position & Behaviour */
		box-sizing: content-box;
		display: inline-block;
		padding: 0.3em 100% 0.3em 0;
		white-space: nowrap;
		font-size: 2.25rem;

		/* Animation */
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		-webkit-animation-name: news-ticker;
		animation-name: news-ticker;
		-webkit-animation-duration: $news-ticker-duration;
		animation-duration: $news-ticker-duration;

		&__item {
			display: inline-block;
			padding: 0 2rem;
		}
	}
}

html.no-breaking-news .news-ticker-wrap {
	display: none;
}

@-webkit-keyframes news-ticker {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}

	100% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}

@keyframes news-ticker {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}

	100% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}
