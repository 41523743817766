/* ==========================================================================
   General
   ========================================================================== */

/* Featured Image
   ========================================================================== */

html {
	height: 100%;
	transition: background-image 0.4s ease-in-out 0.1s;
}

body {
	height: 100%;
	margin: 0;
}

/* Main Wrapper
   ========================================================================== */

.main-wrap {
	max-width: 100%;
	width: 100%;
	overflow: hidden;
}

.bottom-wrap {
	max-width: 100%;
	width: 100%;
}

.toc-news-ticker-wrap {
	max-width: 100%;
	width: 100%;
	overflow: hidden;
}

.content-toc-wrap,
.content-box-wrap {
	max-width: 100%;
	width: 100%;
}

@media (orientation: portrait) {
	.main-wrap {
		height: 100%;
		min-height: 100%;
		display: flex;
		flex-direction: column;
	}
}

/* Toc & News Wrapper for Landscape Mode
   ========================================================================== */

@media (orientation: landscape) {
	.toc-news-ticker-wrap {
		position: absolute;
		bottom: 0;
		width: 100%;
		overflow: hidden;
	}
}

/* Featured Image & Placeholder
   ========================================================================== */
html,
.top-wrap-portrait {
	background: url(../../assets/assets_featured-image-placeholder.jpg) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

html.no-featured-image,
html.no-featured-image .top-wrap-portrait {
	background: url(../../assets/assets_featured-image-placeholder.jpg) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

/* Featured Image Landscape Adaptions
   ========================================================================== */

@media (orientation: landscape) {
	.bottom-wrap {
		font-size: 0.75vw;
	}
}

/* Featured Image Portrait Adaptions
   ========================================================================== */
@media (orientation: landscape) {
	.top-wrap-portrait {
		display: none;
	}
}

@media (orientation: portrait) {
	.top-wrap-portrait {
		flex: 1;
	}
}
