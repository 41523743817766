@import '../helpers/variables.scss';

/* Progression Meter
   ========================================================================== */
$animationTime: 20s;

@media (orientation: landscape) {
	.content-box-meter-wrap-portrait {
		display: none;
	}
}

@media (orientation: portrait) {
	.content-box-meter-wrap-landscape {
		display: none;
	}
}

.content-box__meter {
	height: 1.75em;
	position: relative;
	background: lighten($content_accent_bg_color, 20%);
	overflow: hidden;
}

.content-box__meter span {
	display: block;
	height: 100%;
}

html.single-post .content-box__meter {
	display: none;
}

.content-box__progress {
	background-color: $content_accent_bg_color;
	-webkit-animation: progressbar $animationTime linear !important;
	-webkit-animation-fill-mode: both;
	-moz-animation: progressbar $animationTime linear;
	-moz-animation-fill-mode: both;
}

@keyframes progressbar {
	0% {
		width: 1px;
	}

	100% {
		width: 100%;
	}
}

@-webkit-keyframes progressbar {
	0% {
		width: 1px;
	}

	100% {
		width: 100%;
	}
}

@-moz-keyframes progressbar {
	0% {
		width: 1px;
	}

	100% {
		width: 100%;
	}
}
