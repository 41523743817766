/* ==========================================================================
   Variables
   ========================================================================== */

/* Fonts
   ========================================================================== */

$content_font_family: 'Lato',
	sans-serif;

$headline_font_family: 'Lora',
	serif;

/* Colors
	  ========================================================================== */

/* Content Box */
$content_text_color: #282828;
$content_bg_color: #fff;
$content_accent_bg_color: #58ae96;
$content_accent_text_color: #fff;
$content_author_color: #838383;

/* News Ticker */
$newsticker_text_color: #fff;
$newsticker_bg_color: #dd3c5d;

/* Table of contents */
$toc_headline_text_color: #fff;
$toc_headline_bg_color: #58ae96;
$toc_text_color: #fff;
$toc_bg_color: #1f82c0;

/* Loading Indicator Color */
$loading_indicator-cube1-color: #69bfac;
$loading_indicator-cube2-color:#fff;
$loading_indicator-cube3-color: #b1c800;
$loading_indicator-cube4-color: #009374;

/* Other
	  ========================================================================== */
$news-ticker-duration: 30s;
