@import '../helpers/variables.scss';

/* ==========================================================================
   Content Box
   ========================================================================== */

.content-box-wrap {
	/* Color */
	background-color: $content_bg_color;
}

@media (orientation: landscape) {
	.content-box-wrap {
		/* Position & Behaviour */
		margin-top: 7em;
		margin-left: 2em;
		max-width: 58%;
	}

	.content-box {
		-webkit-box-shadow: 1.25em -1.25em 0 0 rgba(255, 255, 255, 0.5);
		-moz-box-shadow: 1.25em -1.25em 0 0 rgba(255, 255, 255, 0.5);
		box-shadow: 1.25em -1.25em 0 0 rgba(255, 255, 255, 0.5);
	}
}

@media (orientation: portrait) {
	.content-box-wrap {
		// no code needed
	}
}

.content-box {
	/* Color */
	color: $content_text_color;

	h1 {
		font-family: $headline_font_family;
		font-size: 3.75rem;
		margin: 0.2em 0;
	}
}

/* Content Box Inner
   ========================================================================== */

.content-box-inner {
	/* Position & Behaviour */
	padding: 3.5em;
	position: relative;
}

.content-box-inner__meta {
	font-size: 2.25rem;
	font-weight: 300;
	margin: 0;
}

.content-box-inner__author {
	font-size: 1.75rem;
	color: $content_author_color;
	margin: 0;
}

.content-box-inner__copy {
	font-size: 2.25rem;
	line-height: 1.5em;
}
