@import '../helpers/variables.scss';

/* ==========================================================================
   Table of Contents
   ========================================================================== */

.toc {
	/* Typographie */
	font-size: 1.75rem;
	max-height: 50vh;
	overflow-y: hidden;

	ul {
		/* Typographie */
		font-family: $headline_font_family;
		line-height: 1.5em;

		/* Position & Behaviour */
		background-color: $toc_bg_color;
		color: $toc_text_color;
		margin: 0;
		padding: 1em 1em 2em 1em;

		li {
			margin: 1.25em 0;
			//animation: scrollAnimation 10s linear infinite;
		}

		li:first-child {
			margin-top: 0;
		}

		li:last-child {
			margin-bottom: 0;
		}
	}
}

// @keyframes scrollAnimation {
//     0% {
//         transform: translateY(0);
//     }

//     100% {
//         transform: translateY(calc(-100% - 2em));
//     }
// }

@media (orientation: landscape) {
	.toc {
		/* Position & Behaviour */
		max-width: 18%;
		margin-left: auto;
	}
}

@media (orientation: portrait) {
	.content-toc-wrap {
		display: flex;
		background: #fff;
	}

	.toc {
		flex: 2;
		margin-right: 3.5rem;
		margin-top: auto;

		ul {
			line-height: 1.25em;
		}
	}
}

.toc__header {
	/* Color */
	background-color: $toc_headline_bg_color;
	color: $toc_headline_text_color;

	/* Position & Behaviour */
	margin: 0;
	padding: 0.5em;
}

@media (orientation: landscape) {
	.toc__header {
		border-radius: 8px 0 0 0;
		-moz-border-radius: 8px 0 0 0;
		-webkit-border-radius: 8px 0 0 0;
	}
}

@media (orientation: portrait) {
	.toc__header {
		border-radius: 8px 8px 0 0;
		-moz-border-radius: 8px 8px 0 0;
		-webkit-border-radius: 8px 8px 0 0;
	}
}

.toc__item {
	list-style: none;
	margin: 1em 0;
}
