/* ==========================================================================
   Typography
   ========================================================================== */

/* General
   ========================================================================== */

html {
	font-size: 16px;
	font-family: $content_font_family;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;

	/* legacy properties */
	-webkit-hyphenate-limit-before: 4;
	-webkit-hyphenate-limit-after: 3;

	/* -moz-hyphenate-limit-chars: 8 4 3;      not yet supported */

	/* -webkit-hyphenate-limit-chars: 8 4 3;   not yet supported */
	-ms-hyphenate-limit-chars: 8 4 3;
	hyphenate-limit-chars: 8 4 3;
}

@media (orientation: landscape) {
	html {
		font-size: 0.75vw;
	}
}

@media (orientation: portrait) {
	html {
		font-size: 0.75vh;
	}
}
